import "../../../turkish/faaliyetler/gecmis_davalar_islemler/GecmisDavalarIslemler.css";
import Header from "../../header/Header";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import React from 'react'

export default function GecmisDavalarIslemler() {
    return (
        <div>
             <Header
                
                title="案例 " 
                icon={<BusinessCenterIcon style={{fontSize: "x-large"}}/>}
                
            />
            <div className="row" style={{paddingTop: "2rem", minHeight:"30rem"}}>
             
            
             <div className="col-12 gecmisdava" style={{padding:"2rem",fontSize:"medium"}}>
               

                <ol style={{paddingLeft:"1rem"}}>
                    <li> <b>商事、经济案件</b>
                        <ol style={{listStyleType:"lower-latin"}}>
                            <li>江苏某机械制造有限公司：拖欠货款案 <br/>
                            2016年6月我方诉讼胜诉，并于同日查封冻结拖欠货款公司资产和土地。依据土耳其盖布载地权总局2017年5月29日拍卖号11976，伊兹密尔第四执行局令
                            </li>
                            <li>浙江湖州某链轮有限公司： 货款拖欠案。<br/>
                            本案在尽职调查期间，在使馆敕令和关注下，对方已经完全处于被动状态，我方在克服层层阻力下，已经将全部证据锁定，已经获得完成证据收集固定工作。
                            </li>
                            <li>浙江某机械集团进出口公司与土耳其某进出口公司国际贸易纠纷案。<br/>
                            该贸易纠纷案为土耳其公司恶意诈骗案，涉案金额：312021美元
                            </li>
                            <li>泰州某绝缘材料有限公司与土耳其公司某集团公司国际贸易纠纷案。<br/>
                            该贸易纠纷为土耳其公司受经济影响延迟付款案，涉案金额：115723美元。
                            </li>
                            <li>深圳市某实业有限公司与土耳其某公司国际贸易纠纷案 <br/>
                            该贸易纠纷案为恶意拖欠和使用虚假文件骗取海关货物，涉案金额：185630美元。
                            </li>
                            <li>杭州某进出口有限公司与土耳其某国际贸易纠纷案 <br/>
                            该贸易纠纷为土耳其公司利用转港贸易恶意违约拖欠案，涉案金额：64430美元。
                            </li>
                            <li>中国浙江某实业有限公司与土耳其某公司. 国际贸易纠纷案。<br/>
                            该贸易纠纷为土耳其公司利用转港贸易恶意违约拖欠案，涉案金额：271266美元
                            </li>
                            <li>湖州某进出口有限公司与土耳其某公司国际贸易纠纷案。
                            该案为土耳其公司恶意拖欠贸易款，贸易金额：121600美元.案件进度 <br/>
                            案件进度：由于案发时间跨度大，土耳其公司转让，该案经过审理已经移送中国管辖。</li>
                            <li>中国某机械制造有限公司与土耳其某进出口公司国际贸易纠纷案。
                            该案为土耳其经济形势不好造成的政策性拖欠货款，<br/>
                            欠款金额：436143.65美元。 
                            </li>
                            <li>还有涉及江苏省、浙江省、山东省、上海市等数起案件纠纷。</li>
                        </ol>

                    </li>
                    <br/>
                    <li> <b>领事保护类</b>
                        <ol style={{listStyleType:"lower-latin",fontSize:"medium"}}>
                            <li>20141217卡帕多起亚热气球空难死亡事故 (国际首例)
                            合作律师：穆拉特律师
                        ，我方刑事胜诉。推翻对方不可抗力辩护，依法判定对方责任事故罪，该案进入经济赔偿诉讼阶段
                        </li>
                                                    <li>空亚交通事故案：交通事故
                        合作律师：欧勒汗律师
                        2016年8月3日在空亚发生中国人恶性交通事故。于2016年12月空亚检察院正式立案，并于2017年5月以过失伤害罪正式刑事起诉涉案当事人，依据刑法依法判处对方服刑
                        </li>
                                                    <li>2016719特拉巴宗四川女孩坠楼死亡案
                            独立办案
                        2016年7月19日，中国女孩某洋在特拉巴宗吸食毒品后跳楼死亡。该案在土耳其引起极大震动，当地警方以“吸毒致幻跳楼自杀结案”。为了中国人的尊严和荣誉本案在使馆大力关注下，经过多方努力，已经推翻土耳其警方自杀认定，并获得检察院总检察长和主审检察官的认定。
                        </li>
                                                    <li>2017314热气球坠落案
                        合作律师：欧勒汗律师
                        2017年3月14日，在土耳其卡帕多起亚发生3球连环坠落事件，
                        中国人受伤14人。于2017年9月5日提起刑事、民事、保险3诉讼。2018年6月刑事诉讼部分胜诉
                        </li>
                                                    <li>新生婴儿医疗事故伤害案
                            合作律师：穆拉特律师
                        2017年1月4日，某大型企业员工妻子在伊斯坦布尔一家妇产医院产下一男婴，由于在生产过程中医务人员操作失误，新生儿造成不可逆转的残疾。目前该案即将进入诉讼时效期，全部工作正在紧张有序进行。
                        </li>
                            
                        </ol>   
                    </li>
                    <br/>
                    <li> <b>涉恐及刑事案件</b>
                        <ol style={{listStyleType:"lower-latin",fontSize:"medium"}}>
                        <li>201708某某凯、某某振涉恐案（国际中国公民涉恐入罪服刑8个月后改判无罪少有胜诉的个案）
                            合作律师：欧勒汗律师
                        我协会律师在接到委托后在重刑法院二次重刑刑事法庭审理中经过庭审辩护，最终以证据不足当庭释放，并于2018年7月提请国家民事赔偿，11月民事赔偿胜诉，目前就赔偿金额已经转法院专家组进行专家论证。论证结束后，由国家财政进行赔偿支付。
                        </li>
                                                    <li>20181125某某波涉嫌走私案
                        合作律师：欧勒汗、麦莱律师
                        2018年11月25日，河南籍公民某某波受新疆公民介绍在土耳其安卡拉牙医器械参展，期间因为销售没有报税商品被土耳其海关以“走私罪”限制出境。12月5日向大使馆求救，12月8日在接到使馆领保通知后马上集合合作律师欧勒汗及麦莱两位知名律师进行维权，并于同年12月24日第一次庭审驳回安卡拉检察院要求羁押请求，并同时将案件由羁押申请变更为回国等待审理结果。
                        </li>
                        </ol>
                    </li>
                    <br/>
                    <li> <b>中国人对中国人欺骗的案件报案</b>
                        <ul style={{fontSize:"medium"}}>
                            <li>2019年8月10日接到伊兹密尔中国企业报警，被中国公民欺骗</li>
                        </ul>
                    </li>
                    <li><b>孔亚交通事故胜诉案</b></li>
                    <li><b>卡帕多奇亚 热气球上诉案胜诉</b></li>
                </ol>
               
               
             </div>
         </div>
        </div>
    )
}
