/* eslint-disable react/jsx-no-comment-textnodes */
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import db from "./firebase";
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import TrFlag from "./turkish.ico";
import Navbar from "./components/turkish/navbar/Navbar";
import Logo from "./components/turkish/logo/Logo";
import Footer from "./components/turkish/footer/Footer";
import Home from "./components/turkish/home-page/Home";
import Baskan from "./components/turkish/kurumsal/baskan-mesaji/Baskan";
import Yönetim from "./components/turkish/kurumsal/yonetim_kurulu/YonetimKurulu";
import Üyeler from "./components/turkish/kurumsal/uyelerimiz/Uyeler";
import Tüzük from "./components/turkish/kurumsal/tüzük/Tüzük";
import Üyelik from "./components/turkish/kurumsal/uyelik_basvurusu/UyelikBasvurusu";
import UyelerinHukukiHizmetleri from "./components/turkish/hizmetler/uyelerin_hukuki_hizmetleri/UyelerinHukukiHizmetleri";
import İkametCalısmaİzni from "./components/turkish/hizmetler/ikamet_calisma_izni/İkametCalısmaİzni";
import HukukiYardım from "./components/turkish/hizmetler/hukuki_yardım/HukukiYardım";
import HukukiDanısma from "./components/turkish/hizmetler/hukuki_danısma/HukukiDanısma";
import MaliDanısma from "./components/turkish/hizmetler/mali_danısma/MaliDanısma";
import OzelDurumlardaHukukiYardım from "./components/turkish/hizmetler/özel_durumlarda_hukuki_yardım/OzelDurumlardaHukukiYardım";
import GecmisDavalarIslemler from "./components/turkish/faaliyetler/gecmis_davalar_islemler/GecmisDavalarIslemler";
import Kesif from "./components/turkish/faaliyetler/kesif/Kesif";
import DavacıDavalıDosya from "./components/turkish/faaliyetler/davacı_davalı_dosya/DavacıDavalıDosya";
import EvlilikIcindeSorun from "./components/turkish/faaliyetler/evlilik_icinde_sorun/EvlilikIcindeSorun";
import Sigorta from "./components/turkish/faaliyetler/sigorta/Sigorta";
import Haber from "./components/turkish/medya/haber/Haber";
import Duyuru from "./components/turkish/medya/duyuru/Duyuru";
import Fotograf from "./components/turkish/galeri/fotograf/Fotograf";
import Video from "./components/turkish/galeri/video/Video";
import Blog from "./components/turkish/medya/blog/Blog";
import Contact from "./components/turkish/contact-page/Contact";
import Notfound from "./components/turkish/notfound/Notfound";
import Admin from "./components/turkish/admin/Admin";
import Egitim from "./components/turkish/investment/turkiyede-egitim/Egitim";
import TurkSaglik from "./components/turkish/investment/turkiyede-saglik/TurkSaglik";
import Yasam from "./components/turkish/investment/turkiyede-yasam/Yasam";
import Yatirim from "./components/turkish/investment/turkiyede-yatirim/Yatirim";
import AltBlog from "./components/turkish/medya/blog/Altblog";
import AltHaber from "./components/turkish/medya/haber/Althaber";

import ChFlag from "./chinese.ico";
import ChiLogo from "./components/chinese/logo/Logo";
import ChiNavbar from "./components/chinese/navbar/Navbar";
import ChiFooter from "./components/chinese/footer/Footer";
import ChiHomeCn from "./components/chinese/home-page/Home";
import ChiHome from "./components/chinese/home-page/Home";
import ChiFotograf from "./components/chinese/galeri/fotograf/Fotograf";
import ChiVideo from "./components/chinese/galeri/video/Video";
import ChiContact from "./components/chinese/contact-page/Contact";
import ChiHaber from "./components/chinese/medya/haber/Haber";
import ChiDuyuru from "./components/chinese/medya/duyuru/Duyuru";
import ChiBlog from "./components/chinese/medya/blog/Blog";
import ChiGecmisDavalarIslemler from "./components/chinese/faaliyetler/gecmis_davalar_islemler/GecmisDavalarIslemler";
import ChiKesif from "./components/chinese/faaliyetler/kesif/Kesif";
import ChiDavacıDavalıDosya from "./components/chinese/faaliyetler/davacı_davalı_dosya/DavacıDavalıDosya";
import ChiEvlilikIcindeSorun from "./components/chinese/faaliyetler/evlilik_icinde_sorun/EvlilikIcindeSorun";
import ChiSigorta from "./components/chinese/faaliyetler/sigorta/Sigorta";
import ChiBaskan from "./components/chinese/kurumsal/baskan-mesaji/Baskan";
import ChiYönetim from "./components/chinese/kurumsal/yonetim_kurulu/YonetimKurulu";
import ChiÜyeler from "./components/chinese/kurumsal/uyelerimiz/Uyeler";
import ChiTüzük from "./components/chinese/kurumsal/tüzük/Tüzük";
import ChiÜyelik from "./components/chinese/kurumsal/uyelik_basvurusu/UyelikBasvurusu";
import ChiUyelerinHukukiHizmetleri from "./components/chinese/hizmetler/uyelerin_hukuki_hizmetleri/UyelerinHukukiHizmetleri";
import ChiİkametCalısmaİzni from "./components/chinese/hizmetler/ikamet_calisma_izni/İkametCalısmaİzni";
import ChiHukukiYardım from "./components/chinese/hizmetler/hukuki_yardım/HukukiYardım";
import ChiHukukiDanısma from "./components/chinese/hizmetler/hukuki_danısma/HukukiDanısma";
import ChiMaliDanısma from "./components/chinese/hizmetler/mali_danısma/MaliDanısma";
import ChiOzelDurumlardaHukukiYardım from "./components/chinese/hizmetler/özel_durumlarda_hukuki_yardım/OzelDurumlardaHukukiYardım";
import ChiAdmin from "./components/chinese/admin/Admin";
import ChiEgitim from "./components/chinese/investment/turkiyede-egitim/Egitim";
import ChiYatirim from "./components/chinese/investment/turkiyede-yatirim/Yatirim";
import ChiSaglik from "./components/chinese/investment/turkiyede-saglik/TurkSaglik";
import ChiYasam from "./components/chinese/investment/turkiyede-yasam/Yasam";
import ChiAltBlog from "./components/chinese/medya/blog/Altblog";
import ChiAltHaber from "./components/chinese/medya/haber/Althaber";

import { SnackbarProvider } from 'notistack';

//chinese
import GenericStore from '../src/stores/GenericStore';
const GenericServiceCh = new GenericStore('blog', 'ch')
const GenericServiceTr = new GenericStore('blog', 'tr')

function App() {

  const [blogs, setBlogs] = useState([]);
  const [haberler, setHaberler] = useState([]);

  const [chiBlogs, setChiBlogs] = useState([]);
  const [chiHaberler, setChiHaberler] = useState([]);

  useEffect(() => {
    getChiBlogs()
  }, []);

  const getChiBlogs = () => {
    GenericServiceCh.get()
      .then(async (data) => {
        setChiBlogs(
          data.map((blog) => ({
            id: blog._id,
            url: blog.photoUrl.replace('?dl=0', '?raw=1'),
            heading: blog.title,
            blogContent: blog.content
          }))
        );
      })
      .catch((err) => {
        console.log(`Oppss ! ${err}`)
      })
  }

  const getTrBlogs = () => {
    GenericServiceTr.get()
      .then(async (data) => {
        setBlogs(
          data.map((blog) => ({
            id: blog._id,
            url: blog.photoUrl.replace('?dl=0', '?raw=1'),
            heading: blog.title,
            blogContent: blog.content
          }))
        );
      })
      .catch((err) => {
        console.log(`Oppss ! ${err}`)
      })
  }

  const [state, setState] = useState(true);

  const handleChange = () => {
    setState(!state);
    if (state) {
      getTrBlogs()
    } else {
      getChiBlogs()
    }
  };
  return (
    <SnackbarProvider>
      <Router>
        <div className="container-fluid app">
          <div className="row" id="menu">
            <div className="row languageOption">
              {state === true ? (
                <Link to="/tr">
                  <label
                    onClick={handleChange}
                    style={{ color: "black", marginTop: "1rem" }}
                  >
                    <img
                      src={TrFlag}
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        marginLeft: "1rem",
                      }}
                    ></img>{" "}
                  &nbsp;Türkçe
                </label>
                </Link>
              ) : (
                <Link to="/">
                  <label
                    onClick={handleChange}
                    style={{ color: "black", marginTop: "1rem" }}
                  >
                    <img
                      src={ChFlag}
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        marginLeft: "1rem",
                      }}
                    ></img>{" "}
                  &nbsp;中文
                </label>
                </Link>
              )}
            </div>


            {state === true ? (
              document.title = "中国公民公司法律保护协会",
              <nav className="navbar" style={{ backgroundColor: "#ffffff" }}>
                <ChiLogo />
              </nav>
            ) : (
              document.title = "Çin-Türk Hukuk Derneği",
              <nav className="navbar" style={{ backgroundColor: "#ffffff" }}>
                <Logo />
              </nav>
            )}


            {state === true ? (
              <nav
                className="navbar menu"
                style={{
                  backgroundColor: "#A4203A",
                  padding: "0%",
                  marginBottom: "0",
                }}
              >
                <ChiNavbar />
              </nav>
            ) : (
              <nav
                className="navbar menu"
                style={{
                  backgroundColor: "#A4203A",
                  padding: "0%",
                  marginBottom: "0",
                }}
              >
                <Navbar />
              </nav>
            )}
          </div>

          <br />

          <div className="container-fluid">
            <Switch>
              <Route exact path="/admin" component={ChiAdmin} />
              <Route exact path="/" component={ChiHome} />
              <Route exact path="/cn" component={ChiHomeCn} />
              <Route exact path="/cn/土耳其的教育" component={ChiEgitim} />
              <Route exact path="/cn/土耳其的健康" component={ChiSaglik} />
              <Route exact path="/cn/在土耳其生活" component={ChiYasam} />
              <Route exact path="/cn/在土耳其的投资" component={ChiYatirim} />
              <Route exact path="/cn/照片" component={ChiFotograf} />
              <Route exact path="/cn/视频" component={ChiVideo} />
              <Route exact path="/cn/联系" component={ChiContact} />
              <Route exact path="/cn/新闻" component={ChiHaber} />
              <Route exact path="/cn/公告" component={ChiDuyuru} />
              <Route exact path="/cn/博客" component={ChiBlog} />
              {/* <Route exact path="/cn/chi-blog-content" component={ChiAltBlog} /> */}

              {chiBlogs.map((blog, index) => (
                <Route
                  exact
                  path={"/cn/博客/" + blog.heading}
                  component={ChiAltBlog}
                />
              ))}
              <Route
                exact
                path="/cn/协会相关服务"
                component={ChiUyelerinHukukiHizmetleri}
              />
              <Route
                exact
                path="/cn/居住证和工作许可证"
                component={ChiİkametCalısmaİzni}
              />
              <Route
                exact
                path="/cn/法律援助"
                component={ChiHukukiYardım}
              />
              <Route
                exact
                path="/cn/法律咨询"
                component={ChiHukukiDanısma}
              />
              <Route exact path="/cn/财务咨询" component={ChiMaliDanısma} />
              <Route
                exact
                path="/cn/特殊情况下的法律援助"
                component={ChiOzelDurumlardaHukukiYardım}
              />

              <Route exact path="/cn/会长致辞" component={ChiBaskan} />
              <Route exact path="/cn/协会章程" component={ChiTüzük} />
              <Route exact path="/cn/我们会员" component={ChiÜyeler} />
              <Route exact path="/cn/管理委员会" component={ChiYönetim} />
              <Route exact path="/cn/会员申请" component={ChiÜyelik} />

              <Route
                exact
                path="/cn/案例"
                component={ChiGecmisDavalarIslemler}
              />
              <Route exact path="/cn/案件调查" component={ChiKesif} />
              <Route
                exact
                path="/cn/原告或被告档案"
                component={ChiDavacıDavalıDosya}
              />
              <Route
                exact
                path="/cn/婚姻和家庭案例"
                component={ChiEvlilikIcindeSorun}
              />
              <Route exact path="/cn/保险" component={ChiSigorta} />
              <Route exact path="/tr" component={Home} />

              <Route exact path="/baskanin-mesaji" component={Baskan} />
              <Route exact path="/tuzuk" component={Tüzük} />
              <Route exact path="/uyelerimiz" component={Üyeler} />
              <Route exact path="/yonetim-kurulu" component={Yönetim} />
              <Route exact path="/uyelik-basvurusu" component={Üyelik} />

              <Route
                exact
                path="/uyelerin-hukuki-hizmetleri"
                component={UyelerinHukukiHizmetleri}
              />
              <Route
                exact
                path="/ikamet-calisma-izni"
                component={İkametCalısmaİzni}
              />
              <Route exact path="/hukuki-yardim" component={HukukiYardım} />
              <Route exact path="/hukuki-danisma" component={HukukiDanısma} />
              <Route exact path="/mali-danisma" component={MaliDanısma} />
              <Route
                exact
                path="/ozel-durumlarda-hukuki-yardim"
                component={OzelDurumlardaHukukiYardım}
              />

              <Route
                exact
                path="/gecmis-davalar-ve-islemler"
                component={GecmisDavalarIslemler}
              />
              <Route exact path="/kesif" component={Kesif} />
              <Route
                exact
                path="/davaci-veya-davali-dosya"
                component={DavacıDavalıDosya}
              />
              <Route
                exact
                path="/aile-hukuku-davalari"
                component={EvlilikIcindeSorun}
              />
              <Route exact path="/sigorta" component={Sigorta} />

              <Route exact path="/haberler" component={Haber} />
              <Route exact path="/duyurular" component={Duyuru} />

              <Route exact path="/fotograflar" component={Fotograf} />
              <Route exact path="/videolar" component={Video} />

              <Route exact path="/blog" component={Blog} />
              {blogs.map((blog, index) => (
                <Route
                  exact
                  path={"/blogs/" + blog.heading}
                  component={AltBlog}
                />
              ))}

              <Route exact path="/iletisim" component={Contact} />

              <Route exact path="/turkiyede-egitim" component={Egitim} />
              <Route exact path="/turkiyede-saglik" component={TurkSaglik} />
              <Route exact path="/turkiyede-yasam" component={Yasam} />
              <Route exact path="/turkiyede-yatirim" component={Yatirim} />

              <Route exact path="/tr-admin" component={Admin} />
              <Route component={Notfound} />
            </Switch>

            <div id="footer">{state === true ? <ChiFooter /> : <Footer />}</div>
              
            <div className="roof-location">
              <a href="https://www.google.com/maps/place/%C3%87ankaya,+Cinnah+Cd.+67-69,+06690+%C3%87ankaya%2FAnkara/@39.891166,32.8539563,17z/data=!3m1!4b1!4m5!3m4!1s0x14d34f8255924da3:0x21e8de7da6a71210!8m2!3d39.891166!4d32.856145" className="location_float" target="_blank" rel="noopener noreferrer">
                  <LocationOnIcon className="location-icon"/>
              </a>
          </div>

          <div className="roof-phone">
              <a href="tel: +903124665666" className="phone_float" target="_blank" rel="noopener noreferrer">
                  <PhoneIcon className="phone-icon"/>  
              </a>
          </div>

          <div className="roof-whatsapp">
              <a href="https://wa.me/+905531688953" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
                  <WhatsAppIcon className="whatsapp-icon"/>
              </a>
          </div>

          </div>
        </div>
      </Router>
    </SnackbarProvider>
  );
}

export default App;
